
import { getPhysicalCondition, savePhysicalCondition } from '@/api/diary';
import { Form } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
})

export default class extends Vue {
  mounted() {
    this.getPhysicalCondition();
  }

  private loading = true;

  private form: any = {
    muscleWeight: 0,
    fatRate: 0,
    weight: this.$route.query.weight,
    purposeWeight: this.$route.query.goalWeight,
    goalDate: this.$route.query.goalDate,
    diaryDate: '',
  }

  private rules = {
    weight: [
      { required: true, message: '체중을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    muscleWeight: [
      { required: true, message: '골격근량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    fatRate: [
      { required: true, message: '체지방율을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
  }

  private handleSavePhysicalCondition() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        this.form.diaryDate = this.$route.query.diaryDate;
        savePhysicalCondition(this.form).then(() => {
          this.$message.success('체성분 상태가 기록되었습니다.');
          this.$router.push({ name: 'Diary', query: { ...this.$route.query, tab: 'body' } });
        });
      }
    });
  }

  private getPhysicalCondition() {
    if (this.$route.query.diaryDate) {
      this.loading = true;
      getPhysicalCondition({ searchDate: this.$route.query.diaryDate }).then((res) => {
        // this.form = res.data;
        this.form = {
          ...this.form,
          idx: res.data.idx,
          fatRate: res.data.fatRate,
          muscleWeight: res.data.muscleWeight,
          purposeFatRate: res.data.purposeFatRate,
          purposeMuscleWeight: res.data.purposeMuscleWeight,
        };
        this.loading = false;
      });
    } else {
      this.$message.warning('잘못된 접근입니다.');
      this.$router.push({ name: 'Diary' });
    }
  }
}
